import React from "react";
import Layout from "../components/layout";
import Title from "../components/title";
import Section from "../components/section";
import Feature from "../components/feature";
import { Helmet } from 'react-helmet';
import { Link } from "gatsby";

import Promo from "../components/promo";

import Schedule from "../../static/icons/schedule.inline.svg";
import Targets from "../../static/icons/targets.inline.svg";
import Email from "../../static/icons/email.inline.svg";
import Reminders from "../../static/icons/reminders.inline.svg";
import Projects from "../../static/icons/projects.inline.svg";

export default ({ location }) => (

    <Layout
      path={location.pathname}>

      <Helmet
        bodyAttributes={{
          class: 'bg-gray-100 text-root'
        }} />

      <Title
        title="A planning tool for freelancers"
        width="max-w-sm md:max-w-xl">

        <p className="max-w-2xl leading-relaxed mx-auto mt-8 mb-12 text-lg md:text-xl"> 
          Build better habits by visualizing your schedule, forecasting your finances and automating invoices.
          $29/month or $290/year.
        </p>

        <Link to="https://app.heyslate.com/register" className="btn hover:bg-gray-400 hover:border-gray-400 hover:text-root">
          <span clasName="text-accent">Sign up for free</span>
        </Link>

        <div className="hidden sm:flex items-center justify-center space-x-8 mx-auto mt-16 text-dark">
          <div className="flex items-center space-x-2">
            <svg viewBox="0 0 15 15" fill="none" xmlns="http://www.w3.org/2000/svg" width="15" height="15"><path d="M4 7.5L7 10l4-5m-3.5 9.5a7 7 0 110-14 7 7 0 010 14z" stroke="currentColor"></path></svg>
            <span>Free trial</span>
          </div>
          <div className="flex items-center space-x-2">
            <svg viewBox="0 0 15 15" fill="none" xmlns="http://www.w3.org/2000/svg" width="15" height="15"><path d="M4 7.5L7 10l4-5m-3.5 9.5a7 7 0 110-14 7 7 0 010 14z" stroke="currentColor"></path></svg>
            <span>No credit card required</span>
          </div>
          <div className="flex items-center space-x-2">
            <svg viewBox="0 0 15 15" fill="none" xmlns="http://www.w3.org/2000/svg" width="15" height="15"><path d="M4 7.5L7 10l4-5m-3.5 9.5a7 7 0 110-14 7 7 0 010 14z" stroke="currentColor"></path></svg>
            <span>Cancel anytime</span>
          </div>
        </div>

      </Title>

      <div className="max-w-8xl xl:px-12 mx-auto relative">
        <div className="relative pb-16/9 rounded-2xl overflow-hidden bg-shadow">
          <video className="outline-none absolute w-full h-full object-cover" autoPlay muted loop playsinline>
            <source src="/videos/feature_schedule.mp4" type="video/mp4" />
          </video>
        </div>
        <div className="blur"></div>
      </div>

      <div className="mt-12 space-y-12 md:mt-16 md:space-y-16 lg:mt-24 lg:space-y-24 xl:mt-32 xl:space-y-32">

        <Section
          sub="Schedule"
          colour="blue-300"
          title="Visualize your schedule"
          width="md:max-w-md xl:max-w-lg"
          image="preview/schedule.svg">

          <p className="leading-relaxed mt-4 mb-8 text-dark lg:text-lg">
            See what you have planned and when you're next available. Never overbook yourself again.
          </p>

        </Section>

        <Section
          sub="Forecast"
          flip="lg:flex-row-reverse lg:space-x-reverse xl:space-x-reverse"
          colour="pink-300"
          title="Forecast your revenue"
          width="md:max-w-md xl:max-w-lg"
          image="preview/forecast.svg">

          <p className="leading-relaxed mt-4 mb-8 text-dark lg:text-lg">
            Get an instant snapshot of your finances. See how you're doing compared with the target you set.
          </p>

        </Section>

        <Section
          sub="Invoice"
          colour="orange-300"
          title="Automate your invoices"
          width="md:max-w-md xl:max-w-lg"
          image="preview/invoice.svg">

          <p className="leading-relaxed mt-4 mb-8 text-dark lg:text-lg">
            Keep track of the work you've already invoiced, and the work you still need to invoice.
          </p>

        </Section>

      </div>

    </Layout>
)