import React from "react";
import { Link } from "gatsby";
import Arrow from "../../static/icons/arrow.inline.svg";

function Section(props) {
  return (
    <div className={`max-w-6xl mx-auto items-center md:flex md:justify-between md:space-x-16 lg:space-x-24 xl:space-x-40 ${props.flip}`}>

      <div className="md:w-1/2">

      {props.sub && (
        <div className={`inline-block mb-4 lg:mb-8 text-sm text-root py-2 px-4 rounded-full pointer-events-none bg-${props.colour}`}>
          {props.sub}
        </div>
      )}

        <h2
          className={`text-2xl lg:text-3xl leading-none ${props.width}`}
          dangerouslySetInnerHTML={{ __html: props.title }}>
        </h2>

        { props.children }

      </div>

      {props.image && (
        <div className="md:w-1/2 rounded-xl overflow-hidden border border-gray-300">
          <img src={props.image} className="w-full rounded-t-xl overflow-hidden" alt={props.title} />
        </div>
      )}

    </div>
  );
}

export default Section;